import React from "react"

import { Nav } from "../Nav"
import { Main } from "../Main.styles"
import { Footer } from "./Footer"

export const Layout = ({ children }) => {
  return (
    <div className="min-h-screen w-full flex flex-col">
      <Nav />

      <Main>{children}</Main>

      <Footer />
    </div>
  )
}
