import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import tw from "twin.macro"
import { css } from "styled-components/macro"

export const Footer: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      npoSvlImage: file(relativePath: { eq: "footer-logo.jpg" }) {
        childImageSharp {
          fixed(width: 488, height: 71, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <footer
      css={[
        tw`flex-shrink-0 flex`,
        css`
          height: 71px;
          background: #101010;
          overflow: hidden;
        `,
      ]}
    >
      <Img fixed={data.npoSvlImage.childImageSharp.fixed} css={tw`mx-auto`} />
    </footer>
  )
}
