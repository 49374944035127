import React from "react"
import { YMaps, Map, Placemark, ZoomControl } from "react-yandex-maps"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import { Layout } from "../components/Layout"
import Line7 from "../images/line_7.png"
import Line8 from "../images/line_8.png"
import LineD2 from "../images/line_d2.png"
import Seo from "../components/Seo"

const Employee: React.FC<{
  fio: string
  position: string
  photo: any
  mobPhone: string
}> = ({ fio, position, photo, mobPhone }) => {
  const [lastName, ...rest] = fio.split(" ")

  return (
    <div className="mb-3">
      {/* <Img fixed={photo.childImageSharp.fixed} /> */}

      <div className="font-bold">{position}:</div>
      <div>
        {lastName} {rest.join(" ")}
      </div>
      {/* <div>
        {lastName} {rest.join(" ")}
      </div> */}
      {/* <div className="font-bold mt-6">{lastName}</div>
      <div className="-mt-1 mb-2">{rest.join(" ")}</div> */}
      <div>
        {mobPhone ? (
          <a
            className="link_a"
            style={{
              color: "#3399ff",
            }}
            href={`tel: ${mobPhone}`}
          >
            {mobPhone}
          </a>
        ) : (
          ""
        )}
      </div>
    </div>
  )
}

export default function Contacts({ data }) {
  return (
    <Layout>
      <Seo title="Контакты" />

      <div className="max-w-3xl mx-auto">
        <h1 className="text-center font-bold mb-20">
          ООО "НПО Системы Взаимодействия с Локомотивом"
        </h1>

        <div className="   mb-12">
          {/* <div className="font-bold">Руководство:</div> */}
          {data.allEmployees.edges.map(({ node }) => {
            return <Employee key={node.id} {...node} />
          })}
        </div>

        <div className="mb-10">
          <div className="font-bold">Гарантийный отдел:</div>
          <div>Начальник Корниленко Вадим Дмитриевич</div>
          <div>
            <a
              className="link_a"
              style={{
                color: "#0099ff",
              }}
              href="tel:+7 929 554-30-67"
            >
              {" "}
              +7 929 554-30-67
            </a>
          </div>
          <div>
            <a
              href="mailto:kornilenko@nposvl.ru"
              className="link_a"
              style={{
                color: "#0099ff",
              }}
            >
              kornilenko@nposvl.ru
            </a>
          </div>
          <div>График работы: пн-пт с 8:00 до 17:00</div>
          <div>
            109029 г. Москва, улица Нижегородская, дом 32, стр. Б, каб. 202
          </div>
        </div>

        <div className="mb-10">
          <div className="font-bold">Как к нам проехать:</div>
          <div>г. Москва, улица Нижегородская, дом 32, строение 5</div>
        </div>

        <div className="mb-12">
          <div className="font-bold">Ближайшие станции метро:</div>
          <div>
            <img src={Line8} className="inline" /> Площадь Ильича
          </div>
          <div>
            <img src={Line7} className="inline" /> Пролетарская
          </div>
          <div>
            <img src={LineD2} className="inline" /> Калитники
          </div>
        </div>

        <div className="mb-12">
          <YMaps>
            <Map
              defaultState={{ center: [55.736691, 37.693857], zoom: 14 }}
              width="100%"
              height={350}
            >
              <ZoomControl />
              <Placemark defaultGeometry={[55.736691, 37.693857]} />
            </Map>
          </YMaps>
        </div>

        <div className="mb-12">
          <div className="font-bold">Как написать:</div>
          <div>
            109029, г. Москва, ул. Нижегородская, дом 32, строение Б, этаж 2,
            офис 203
            <span
              className="glyphicon glyphicon-earphone"
              // style={{ paddingLeft: 10, paddingRight: 10 }}
            ></span>
            <div>
              <a
                className="link_a"
                style={{
                  color: "#0099ff",
                }}
                href="tel:+7 499 340-37-76"
              >
                {" "}
                +7 499 340-37-76
              </a>
            </div>
            <div className="mb-10">
              <a
                href="mailto:info@nposvl.ru"
                className="link_a"
                style={{
                  color: "#0099ff",
                }}
              >
                info@nposvl.ru
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allEmployees: allEmployeesJson {
      edges {
        node {
          id
          fio
          position
          mobPhone
          photo {
            childImageSharp {
              fixed(width: 240, height: 320) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  }
`
